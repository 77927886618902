<template>
    <page-header-wrapper>
        <template slot="extra">
            <a-button type="primary" @click="add" v-action:add>新增</a-button>
        </template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper" v-action:query>
                <a-form layout="inline">
                    <a-row :gutter="24">
                       <!-- <a-col :md="8" :sm="24">
                            <a-form-item label="时间筛选：">
                                <a-range-picker @change="getDate" v-model="queryParam.time" />
                            </a-form-item>
                        </a-col> -->
                        <a-col :md="8" :sm="24">
                            <a-form-item label="关键词">
                                <a-input style="width: 80%" placeholder="请输入" v-model="queryParam.keyword" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="4 || 24" :sm="24">
                            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>

            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto" :scroll="{x:1500}">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
				<span slot="answer" slot-scope="answer">
					<ellipsis :length="50" tooltip class="ellip">
					    {{answer}}
					</ellipsis>
				</span>
                <span slot="time" slot-scope="create_time">
                    {{create_time | time}}
                </span>
                <span slot="operation">
                    --
                </span>
                <span slot="action" slot-scope="text, record">
                    <template>
                        <a @click="handleEdit(record)" v-action:edit>编辑</a>
                        <a-divider type="vertical" v-action:edit />
                        <a @click="handleDel(record)" v-action:del>删除</a>
                    </template>
                </span>
            </s-table>

          
            <!-- 发布 -->
            <a-modal class="modal_foot_center" v-model="modalVisible" :title="form.id?'编辑问题':'新增问题'" centered 
			@ok="closeModal(1)" @cancel="closeModal(0)">
                <div>
                    <div class="item">
						<div class="item_label"><i>*</i>问题标题</div>
						<div class="item_main"><a-input placeholder="请输入问题标题" v-model="form.question" /></div>
					</div>
					<div class="item">
						<div class="item_label"><i>*</i>问题解答</div>
						<div class="item_main"><a-textarea :rows="6" placeholder="请输入问题解答" v-model="form.answer" /></div>
					</div>
                </div>
            </a-modal>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue';
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
        getQusList,
        editQus,
		delQus
    } from '@/api/article'

    const columns = [
        {
            title: '序号',
            scopedSlots: {
                customRender: 'serial'
            },
            align:'center',
            width:'90px'
        },
        {
            title: '问题标题',
            dataIndex: 'question',
            align:'center',
			width:'280px'
        },
		{
		    title: '问题解答',
		    dataIndex: 'answer',
			scopedSlots: {
			    customRender: 'answer'
			},
		    align:'center'
		},
        {
            title: '操作人',
            dataIndex: '',
            align:'center',
            scopedSlots: {
                customRender: 'operation'
            },
			width:"90px"
        },
        {
            title: '发布时间',
            dataIndex: 'create_time',
            scopedSlots: {
                customRender: 'time'
            },
            align:'center'
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: {
                customRender: 'action'
            },
            align:'center'
        }
    ]

    const statusMap = {
        0: {
            status: 'default',
            text: '全部'
        },
        1: {
            status: 'default',
            text: '待审核'
        },
        2: {
            status: 'success',
            text: '审核通过'
        },
        3: {
            status: 'error',
            text: '审核拒绝'
        }
    }

    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                visible: false,
                advanced: false,
                // 查询参数
                queryParam: {
                    //time: [],
                    keyword: '',
                    //startTime: '',
                    //endTime: '',
                },
                // 加载数据方法 必须为 Promise 对象
                loadData: parameter => {
                    //console.log('parameter',parameter)
                    const requestParameters = Object.assign({}, parameter, this.queryParam)
                    //console.log('requestParameters',requestParameters)
                    return getQusList(requestParameters)
                        .then(res => {
                            return res.data
                        })
                },
                
                modalVisible: false, //发布弹窗
                url:'',   //微信文章url

                title:'',
                img_url:'',
                link_url:'',
				
				form:{
					id:'',
					question:'',
					answer:''
				}
            }
        },
		mounted(){
			
		},
        methods: {
            add(){   //新增
                this.modalVisible = true
            },
            closeModal(status) { //回复弹窗关闭 1确定 2取消
                //console.log('status',status)
                let that = this
                if(status==1){
                    let params = {
                        id:this.form.id,
						question:this.form.question,
						answer:this.form.answer
                    }
					
					if(!this.form.question){
						this.$message.success('请输入问题标题')
						return false
					}else if(!this.form.answer){
						this.$message.success('请输入问题解答')
						return false
					}
                    editQus(params).then(res =>{
                        if(res.code==200){
                            that.$message.success(this.form.id?'编辑成功':'添加成功')
                            that.$refs.table.refresh(true)
                            that.modalVisible = false
							that.form.id = ''
							that.form.answer = ''
							that.form.question = ''
                        }
                    })
                }else{
                    this.id = ''
                    this.answer = ''
                    this.question = ''
                }
            },
            getDate(date, dateString) { //时间筛选
                this.queryParam.time = dateString
                this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0]+' 00:00:00': ''
                this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1]+' 23:59:59' : ''
            },
            handleEdit(data){  //编辑
				this.modalVisible = true
				this.form.id = data.id
				this.form.question = data.question
				this.form.answer = data.answer
            },
            handleDel(data){   //删除
				let that = this
				Modal.confirm({
					content:'确认删除吗?',
					onOk(){
						let params = {
							id:data.id
						}
						delQus(params).then(res =>{
							console.log('res',res);
							if(res.code==200){
								that.$message.success('删除成功')
								that.$refs.table.refresh(true)
							}
						})
					},
					onCancel(){
						
					}
				})
            },
            reset() { //重置
                this.queryParam.time = null
                this.queryParam.keyword = ''
                this.$refs.table.refresh(true)
            }
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            },
            statusFilter(type) {
                return statusMap[type].text
            },
            statusTypeFilter(type) {
                return statusMap[type].status
            }
        }
    }
</script>


<style scoped>
.deliv_item{display:flex;align-items: center;margin-top: 36px;}
.deliv_item_label{width: 120px;}
.deliv_item_label i{font-style: normal;color: #FF3B30;}
.modal_item{display: flex;margin-top: 10px;}
.modal_item div:nth-child(1){min-width: 112px;}
.modal_item div:nth-child(2) img{margin-top: 5px;}

.item{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}
.item_label{
	min-width:100px;
}
.item_label i{
	font-style: normal;
	color: #FF3B30;
	margin-right: 3px;
}
.item_main{
	min-width: 1px;
	width: 100%;
}
.ellip{cursor:pointer;}
</style>